.tooltip {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes fade {
  0% {
    opacity: 0;
 }
  100% {
    opacity: 1;
 }
}
.tooltip[tooltip]:hover::before {
  animation: fade 0.2s ease-in-out;
  content: attr(tooltip);
  position: absolute;
  width: max-content;
  z-index: 10000;
  padding: 4px 8px;
  background: #868486;
  box-shadow: 5px 10px 50px rgba(41, 43, 88, 0.2);
  border-radius: 10px;
  color: #fff;
  font-weight: 400;
}
.tooltip.top[tooltip]:hover::before {
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip.bottom[tooltip]:hover::before {
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip.left[tooltip]:hover::before {
  right: 110%;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip.right[tooltip]:hover::before {
  left: 110%;
  top: 50%;
  transform: translateY(-50%);
}