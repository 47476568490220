.skeletonLoader {
  height: 10rem;
  width: 84%;
  background-color: #d3d2d2;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 5px;
  min-height: 1.3em;
  overflow: hidden;
}
.skeletonLoader::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  animation: loading 0.8s infinite;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
