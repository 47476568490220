.rangeInput {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

.rangeInput::-webkit-slider-runnable-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

.rangeInput::-moz-range-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

.rangeInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #bcfb46;
  box-shadow: -407px 0 0 400px #bcfb46;
}

.rangeInput::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #bcfb46;
  box-shadow: -407px 0 0 400px #bcfb46;
}
