.searchCreateTest {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchCreateTest > .search {
  flex: 1;
}
.searchCreateTest > .search:hover {
  background: #f2f3f7;
}

.searchCreateTest > .createTest {
  margin: 0 0 0 2%;
  padding: 0;
}

.hoveringTest:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.testOptions,
.testOptionsDelete {
  display: flex;
  transition: 0.3s all ease;
  justify-content: flex-end;
  padding: 2px 5px;
  align-items: center;
}

.testOptionsText,
.testOptionsTextDelete {
  display: none;
  margin: 0 10px;
  font-size: 0.85rem;
}

.testOptions:hover {
  background-color: #0d7c17;
  color: white;
  border-radius: 10px;
}

.testOptionsDelete:hover {
  background-color: #7c1b09;
  color: white;
  border-radius: 10px;
}

.testOptions:hover .testOptionsText,
.testOptionsDelete:hover .testOptionsTextDelete {
  display: block;
}

/* .testOptions:hover .testOptionsTextDelete {
  background-color: #7c110d;
  color: white;
  border-radius: 10px;
} */

.createTitle {
  border: none;
  border-bottom: 1px solid #212529;
  /* box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px; */
  padding: 10px 15px;
}

.createTitle:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  border-radius: 5px;
  /* box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%); */
}

.createSection {
  border: none;
  border-bottom: 1px solid #212529;
  box-shadow: rgba(0, 0, 0, 0.25) 1.95px 1.95px 2.6px;
  /* padding: 10px 15px; */
}

@media screen and (max-width: 600px) {
  .searchCreateTest {
    flex-direction: column;
  }

  .createTest {
    width: 100%;
    justify-content: center;
  }
}
