.slick-slider .slick-slide {
  padding: 0 18px;
}
.test .slick-list {
  height: 18rem;
}
@media only screen and (max-width: 1024px) {
  .slick-list {
    width: 98%;
  }
  .slick-next {
    right: 0.2rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .slick-list {
    width: 91%;
  }
  .slick-next {
    right: 1rem !important;
  }
}
/* NAVBAR */

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 9vh;
  background: white;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  border-bottom: 0.5px solid rgb(201, 201, 201);
  position: fixed;
  width: 100%;
  z-index: 15;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: relative; */
}

.nav-item {
  margin: 0 5px;
}

.nav-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-login,
.nav-register {
  text-decoration: none;
  color: white;
  background-color: #007bff;
  padding: 6px 16px;
  font-size: 14px;
  border-radius: 20px;
}

.nav-login:hover,
.nav-register:hover {
  background-color: #0069d9;
  color: white;
}

.nav-item:hover > .userLogout {
  display: flex;
}

.userName {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.userName > img {
  width: 50%;
  border-radius: 50%;
}

.userLogout {
  position: absolute;
  display: none;
  /* bottom: 10px; */
  right: 25px;
  background: white;
  padding: 10px 20px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
  transition: all 0.5s;
}

.userLogout:hover {
  background: rgb(206, 206, 206);
}

/* HOME */
.home {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  /* height: 100vh; */
  padding: 2em;
  /* background: rgb(212, 212, 212);
  background: linear-gradient(
    0deg,
    rgba(212, 212, 212, 1) 15%,
    rgba(255, 255, 255, 1) 100%
  ); */
}
@media only screen and (max-width: 840px) {
  .home {
    padding: 1em 0.5rem;
  }
}

/* LOGIN*/

/* .main {
  background: #f2f3f7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
} */

/* .login,
.register {
  display: flex;
  justify-content: center;
  align-items: center; */
/* height: 90vh; */
/* background-color: #f2f3f7;
} */

/*record audio styles*/
audio::-webkit-media-controls-panel {
  background-color: rgb(239, 237, 237);
  height: 3.5rem;
}

.login-svg-input,
.register-svg-input {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: white;
}

.register-svg-input {
  height: 85vh;
}

.login-1-div,
.register-svg-div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-svg,
.register-svg {
  width: 72%;
}

.login-input,
.register-input {
  /* background-color: blueviolet; */
  width: 100%;
  height: 100%;
  padding: 50px 20px;
}

.login-input-div,
.register-input-div {
  width: 100%;
}
.register-input {
  padding: 25px 15px;
}

.register-heading {
  font-size: 2rem;
  font-weight: bolder;
}

.login-register-p,
.register-heading {
  text-align: left;
}

.login-form {
  padding: 50px 10px;
}

.register-form {
  padding: 5px 50px;
}

.register-form-name,
.register-form-email,
.register-form-pass,
.register-form-mobile {
  margin: 0 0 5px 0;
}

.login-form-emailInput,
.login-form-passInput,
.register-form-nameInput,
.register-form-emailInput,
.register-form-passInput,
.register-form-mobileInput {
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  /* border: 1px solid #bcfb46; */
  background-color: #eefecf !important;
  color: #87cf00;
  border: none;
  padding: 10px;
  margin: 2px 0;
}
.inputPlaceholder::placeholder {
  color: rgba(135, 207, 0, 0.7);
}

.register-form-nameInput,
.register-form-emailInput,
.register-form-passInput,
.register-form-mobileInput {
  margin: 3px 0;
  padding: 6px;
}

.register-form-selectInput {
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  /* border: 1px solid #ced4da; */
  padding: 10px;
  margin: 2px 0;
}

.login-loginBtn,
.register-signupBtn {
  width: 100%;
  padding: 10px 0;
  font-size: 1.15rem;
  cursor: pointer;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
}

.login-loginBtn:hover,
.register-signupBtn:hover {
  background-color: #0069d9;
}

.user-types {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px 0;
}

canvas {
  height: 100 !important;
}

.user-types label {
  margin-right: 10px;
}
/* 
.wrapper {
  display: flex;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
} */

#sidebar {
  /* width: 70px;
  left: 0; */
  width: 125px;
  left: -125px;
  position: absolute;
  display: flex;
  justify-content: center;
  transition-duration: 300ms;
  z-index: 48;
  background: white;
}

#sidebar-open {
  width: 125px;
  position: fixed;
  left: 0px;
  display: flex;
  justify-content: center;
  transition-duration: 300ms;
  z-index: 48;
  background: white;
}

#sidenav {
  /* width: 220px; */
}
/* .link-item__text {
  opacity: 0;
  visibility: hidden;
}
.link-item__text::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #dcf5f5;
  left: -7px;
  top: 45%;
  transform: rotate(45deg) translateY(-50%);
}

#sidebar .link-item:hover .link-item__text {
  opacity: 1;
  visibility: visible;
  transform: translateX(-10px) translateY(-50%);
} */

.content {
  width: calc(100vw - 125px);
  margin-left: 125px;
  transition-duration: 300ms;
  z-index: 10;
  overflow: hidden;
}

.content-full {
  width: 100vw;
  margin-left: 0;
  /* margin-left: 70px; */
  transition-duration: 300ms;
  overflow: hidden;
  z-index: 10;
}

#sidenav > li > a {
  padding: 0.7rem 0.5rem;
  margin-top: 0.8rem;
  /* border-radius: 0.25rem; */
  /* transition-duration: 100ms; */
  animation: linear 10s;
  color: white;
}

/* #sidenav > li:hover {
  cursor: pointer;
  background-color: #dcf5f5;
} */

#sidenav > li.active {
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); */
  background-color: #dcf5f5;
  /* border-radius: 0 10px 10px 0; */
}

#sidenav > li.active > a {
  color: #047575;
  font-weight: 500;
}

#sidenav > li.not-active > a {
  color: #566474;
  font-weight: 400;
}

/* #sidenav > li:hover > a {
  color: #047575;
  font-weight: 500;
} */

@media (max-width: 1024px) {
  #sidebar {
    margin-left: -125px;
    z-index: 11;
  }

  .content {
    width: 100vw;
    margin-left: 0;
  }

  #sidebar.show {
    z-index: 11;
    margin-left: 0;
  }
}

.hoverPointer {
  cursor: pointer;
}

.spinner {
  -webkit-animation: spinnerAnimation 2s infinite ease;
  animation: spinnerAnimation 2s infinite ease;
}

.createRegister {
  border: none;
  /* border-bottom: 1px solid #212529; */
  box-shadow: rgb(0 0 0 / 15%) 0px 10px 20px, rgb(0 0 0 / 15%) 0px 6px 6px;
  padding: 10px 15px;
  width: 100%;
  border-radius: 5px;
}

/* .createTitle:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  border-radius: 5px;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
} */

@-webkit-keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.date-time {
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
}

.date-time-picker {
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {
  .userNameType {
    display: none;
  }

  .userName {
    justify-content: flex-end;
  }

  .nav-login,
  .nav-register {
    padding: 6px 16px;
    font-size: 12px;
    /* margin: 2px; */
  }
  .nav-item {
    margin: 0 2px;
  }

  .nav-svg > div > svg {
    width: 120px;
  }

  .smallerScreenTest {
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .login-input-div,
  .register-input-div {
    padding: 0 5%;
  }

  .register-heading {
    margin: 5% 0;
  }

  .choiceButtons {
  }
}

.Toastify {
  position: absolute;
  z-index: 999999;
}

/* //animation */

@keyframes quiet {
  25% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.6);
  }
}
@keyframes loud {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(1.2);
  }
}
/* body{
  display: flex;
  justify-content: center;
  background: black;
  margin: 0;padding: 0;
  align-items: center;
  height: 100vh;
} */

.boxContainer1 {
  display: flex;
  justify-content: space-between;
  --boxSize: 4px;
  --gutter: 4px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);
}

.box {
  transform: scaleY(0.4);
  height: 100%;
  width: var(--boxSize);
  background: #BCFB46;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 100px;
}

.box1 {
  animation-name: quiet;
}

.box2 {
  animation-name: normal;
}

.box3 {
  animation-name: quiet;
}

.box4 {
  animation-name: loud;
}

.box5 {
  animation-name: quiet;
}
.login-form-selectInput-button {
  color: #0069d9;
}
