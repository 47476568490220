@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Roboto+Mono:wght@400;700&display=swap");
@import url("@tldraw/tldraw/tldraw.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

.fullscreenClass {
  height: 100vh;
  overflow-y: scroll;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.scrollbar::-webkit-scrollbar {
  height: 0.3rem;
  width: 0.2rem;
 
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #A0DB32;
  border-radius: 1rem;
  
}
/* .scrollbar::-webkit-scrollbar-thumb:active {
    background-color: rgba(212, 195, 240, 1);
  } */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #A0DB32;
}

/* Scroll track styles (Background) */
.scrollbar::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-track:hover,
.scrollbar::-webkit-scrollbar-track:active {
  background: #fff;
}


.dot-typing {
  position: relative;
  left: -9999px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #070411;
  color: #070411;
  box-shadow: 9984px 0 0 0 #070411, 9999px 0 0 0 #070411, 10014px 0 0 0 #070411;
  animation: dotTyping 1s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #070411, 9999px 0 0 0 #070411,
      10014px 0 0 0 #070411;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #070411, 9999px 0 0 0 #070411,
      10014px 0 0 0 #070411;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #070411, 9999px 0 0 0 #070411,
      10014px 0 0 0 #070411;
  }
  50% {
    box-shadow: 9984px 0 0 0 #070411, 9999px -10px 0 0 #070411,
      10014px 0 0 0 #070411;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #070411, 9999px 0 0 0 #070411,
      10014px 0 0 0 #070411;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #070411, 9999px 0 0 0 #070411,
      10014px -10px 0 0 #070411;
  }
  100% {
    box-shadow: 9984px 0 0 0 #070411, 9999px 0 0 0 #070411,
      10014px 0 0 0 #070411;
  }
}

.alerts-border {
  border: 1px #d1d5db solid;

  animation: blink 1s;
  animation-iteration-count: 3;
}

@keyframes blink {
  50% {
    border-color: #ff0000;
  }
}

#chat_prompts::-webkit-scrollbar {
  display: none;
}
.primaryBgGradient {
  background: linear-gradient(155.93deg, #80d5d5 0.56%, #009696 93.57%);
}

.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: -50px;
  left: 0;
  background: #015871;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg)
    repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

.ring {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  background-color: transparent;
  border: 2px solid #BCFB46;
  /* left: 100px;
  top: 100px; */
  box-shadow: none;
  animation-name: ani;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-direction: reverse;
  -webkit-animation-name: ani;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease;
  -webkit-animation-duration: 2s;
  -webkit-animation-direction: reverse;
}

@keyframes ani {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(0.75);
    opacity: 0.75;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes ani {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(0.75);
    opacity: 0.75;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

#r2 {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
#r3 {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

/* for react slick */
.slick-list{
  overflow: visible !important;
}