.videos-container {
  display: flex;
  flex-wrap: wrap;
}

.video-box {
  width: 250px;
  height: 150px;
  margin: 15px 6px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background: #3f4244; */
}

.notify {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #e60000;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  animation: scaleUp 0.75s infinite linear;
}

.dancingProctor {
  animation: proctor 1s infinite linear;
}

@keyframes proctor {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.notify.show {
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}



/* dots animation */
.dotsLoading::after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
  padding-left: 4px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}
/* dots animation */
